import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/Utils/report-head-traning'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'

const exportPdfDetails = async (baseUrl, uri = '/report-heading/detail', orgId, reportTitle, vm) => {
    try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }
      const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
      const pdfContent = [{
        columns: reportHeadData.reportHeadColumn
      },
      { text: reportHeadData.orgName, style: 'org', alignment: 'center' },
      { text: reportHeadData.projectName, style: 'address', alignment: 'center' },
      { text: reportHeadData.address, style: 'address', alignment: 'center' },
      { text: reportTitle, style: 'hh', alignment: 'center' }
    ]
      let allRows = []
      allRows = [
        [
          { text: vm.$t('globalTrans.sl_no'), style: 'th', alignment: 'center' },
          { text: vm.$t('globalTrans.name'), style: 'th', alignment: 'center' },
          { text: vm.$t('elearning_config.organization'), style: 'th', alignment: 'center' },
          { text: vm.$t('globalTrans.nid'), style: 'th', alignment: 'center' },
          { text: vm.$t('globalTrans.mobile'), style: 'th', alignment: 'center' },
          { text: vm.$t('globalTrans.email'), style: 'th', alignment: 'center' }
          // { text: vm.$t('globalTrans.status'), style: 'th', alignment: 'center' }
        ]
      ]
      // table body
      if (vm.$store.state.list.length) {
        vm.$store.state.list.filter((item, index) => {
           const rowItem = [
             { text: vm.$n(index + 1), style: 'td', alignment: 'center' },
             { text: i18n.locale === 'en' ? item.name : item.name_bn, style: 'td', alignment: 'center' },
             { text: i18n.locale === 'en' ? item.org : item.org_bn, style: 'td', alignment: 'center' },
             { text: item.nid, style: 'td', alignment: 'center' },
             { text: (vm.$i18n.locale === 'bn' ? '০' : '0') + vm.$n(item.mobile, { useGrouping: false }), style: 'td', alignment: 'center' },
             { text: item.email, style: 'td', alignment: 'center' }
            //  { text: item.status === 1 ? vm.$t('globalTrans.pending') : (item.status === 2 ? vm.$t('globalTrans.reject') : vm.$t('globalTrans.approved')), style: 'td', alignment: 'center' }
           ]
           allRows.push(rowItem)
        })
      } else {
        const rowItem = [
          { text: vm.$t('globalTrans.noDataFound'), style: 'td', alignment: 'center', colSpan: 7 },
          { },
          { },
          { },
          { },
          { },
          { }
        ]
        allRows.push(rowItem)
      }
      pdfContent.push({
        table: {
          headerRows: 1,
          widths: ['8%', '14%', '25%', '15%', '18%', '20%'],
          body: allRows
        }
      })
      // const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
      var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'Portrait',
        // watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
        styles: {
          th: {
            fontSize: 11,
            bold: true,
            margin: [1, 1, 1, 1]
          },
          header: {
            fontSize: 16,
            bold: true,
            margin: [0, 0, 0, 0]
          },
          org: {
              fontSize: 13,
              bold: true,
              margin: [0, -25, 10, 5]
          },
          address: {
              fontSize: 11,
              margin: [0, 0, 0, 5]
          },
          hh: {
              fontSize: 14,
              bold: true,
              margin: [10, 10, 25, 20]
          },
          td: {
            fontSize: 10,
            margin: [1, 1, 1, 1]
          },
          krishi: {
            margin: [0, 1, 0, 15],
            alignment: 'center'
          }
        }
      }
      pdfMake.createPdf(docDefinition, null, null, null).print()
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
