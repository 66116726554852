<template>
    <b-container fluid>
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('elearning_iabm.training_applications') }}</h4>
          </template>
            <template v-slot:body>
                <b-row>
                    <b-col lg="6" sm="6">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="circular_memo_no"
                      >
                      <template v-slot:label>
                        {{$t('elearning_iabm.circular_memo_no')}}
                      </template>
                      <b-form-input
                        id="circular_memo_no"
                        v-model.lazy="search.circular_memo_no"
                        :placeholder="$t('elearning_iabm.circular_memo_no')"
                      ></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="fiscal_year_id"
                      >
                        <template v-slot:label>
                          {{$t('elearning_config.fiscal_year')}}
                        </template>
                        <v-select name="organization"
                          v-model="search.fiscal_year_id"
                          label="text"
                          :reduce="item => item.value"
                          :options= fiscalYearList
                          :placeholder="$t('globalTrans.select')"
                        />
                      </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col lg="6" sm="12" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          :label="$t('elearning_config.organization')"
                          label-for="organization"
                      >
                        <v-select name="organization"
                            v-model="search.org_id"
                            label="text"
                            :reduce="item => item.value"
                            :options= orgList
                            :placeholder="$t('globalTrans.select')"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="training_type_id"
                      >
                        <template v-slot:label>
                          {{$t('elearning_config.training_type')}}
                        </template>
                        <b-form-select
                          plain
                          v-model="search.training_type_id"
                          :options="trainingTypeList"
                          id="training_type_id"
                          >
                          <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                          </b-form-select>
                      </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="training_category_id"
                        >
                          <template v-slot:label>
                            {{$t('elearning_config.training_category')}}
                          </template>
                          <b-form-select
                            plain
                            v-model="search.training_category_id"
                            :options="trainingCategoryList"
                            id="training_category_id"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="training_title_id"
                        >
                          <template v-slot:label>
                            {{$t('elearning_config.training_title')}}
                          </template>
                          <b-form-select
                            plain
                            v-model="search.training_title_id"
                            :options="trainingTitleList"
                            id="training_title_id"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                      </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                  <b-col lg="6" sm="12">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        :label="$t('elearning_iabm.registration_for')"
                        label-for="registration_for"
                    >
                      <v-select name="registration_for"
                                v-model="search.registration_for"
                                label="text"
                                :reduce="item => item.value"
                                :options= userTypeList
                                :placeholder="$t('globalTrans.select')"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col lg="6" sm="12">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="name"
                    >
                      <template v-slot:label>
                        {{ $t('globalTrans.name')}}
                      </template>
                      <b-form-input
                          id="name"
                          v-model="search.name"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col lg="6" sm="12">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="mobile"
                    >
                      <template v-slot:label>
                        {{ $t('globalTrans.mobile')}}
                      </template>
                      <b-form-input
                          type="number"
                          id="mobile"
                          v-model="search.mobile"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col lg="6" sm="12" class="text-right">
                      <b-button type="button" variant="primary float-right" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
                  </b-col>
                </b-row>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{$t('elearning_iabm.training_applications')}} {{$t('globalTrans.list')}}</h4>
                    </template>
                    <template v-slot:headerAction1>

                    </template>
                    <template v-slot:headerAction>
                        <b-button class="btn_add_new" @click="pdfExport">
                          <i class="fas fa-print"></i>{{  $t('globalTrans.print') }}
                        </b-button>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table thead-class="table_head" striped bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                                        <template v-slot:cell(index)="data">
                                        {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                        <template v-slot:cell(mobile)="data">
                                        {{ ($i18n.locale === 'bn' ? '০' : '0') + $n(data.item.mobile, { useGrouping: false }) }}
                                        </template>
                                        <template v-slot:cell(type_name)="data">
                                        <span class="capitalize">{{ data.item.type_name }}</span>
                                        </template>
                                        <template v-slot:cell(training_start_date)="data">
                                          <span class="capitalize">{{ data.item.training_start_date | dateFormat }}</span>
                                        </template>
                                        <template v-slot:cell(training_end_date)="data">
                                          <span class="capitalize">{{ data.item.training_end_date | dateFormat }}</span>
                                        </template>
                                        <template v-slot:cell(action)="data">
                                          <a href="javascript:" class="btn_table_action table_action_view" title="View Details" v-b-modal.modal-5 @click="details(data.item)"><i class="fas fa-eye"></i></a>
                                          <a href="javascript:" class="btn_table_action table_action_edit" title="Edit" v-b-modal.modal-4 @click="edit(data.item)"><i class="ri-ball-pen-fill"></i></a>
                                        </template>
                                    </b-table>
                                    <b-pagination
                                        align="center"
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                        />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-4" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Form :id="editItemId" :key="editItemId"/>
        </b-modal>
      <b-modal id="modal-5" size="lg" :title="$t('elearning_iabm.training_applications') + ' ' + this.$t('globalTrans.details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
        <b-button @click="pdfExportDetails" class="ml-4 btn-success water-test-pdf-button">
          <i class="fas fa-print"></i> {{  $t('globalTrans.print') }}
        </b-button>
        <Details :item="item" :key="editItemId" ref="details"/>
      </b-modal>

    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import Form from './Form'
import Details from './Details'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import {
  trainingApplicationList,
  trainingApplicationApprove,
  trainingApplicationReject
} from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import ExportPdf from './export_pdf_details'
import Store from '@/store'

export default {
    mixins: [ModalBaseMasterList],
    components: {
      Form, Details
    },
    data () {
      return {
        search: {
          registration_for: '',
          name: '',
          circular_memo_no: '',
          org_id: 0,
          fiscal_year_id: 0,
          training_type_id: 0,
          training_category_id: 0,
          training_title_id: 0,
          training_status: 1,
          mobile: ''
        },
        trainingCategoryList: [],
        trainingTitleList: [],
        item: '',
        trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl
      }
    },
    computed: {
      trainingTypeList: function () {
        return this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
      },
      fiscalYearList: function () {
        return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
      },
      orgList: function () {
        return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
      },
      userTypeList () {
        const userList = [
          { value: 1, text: this.$i18n.locale === 'en' ? 'Trainer' : 'প্রশিক্ষক', text_en: 'Trainer', text_bn: 'প্রশিক্ষক' },
          { value: 2, text: this.$i18n.locale === 'en' ? 'Trainee' : 'প্রশিক্ষণার্থী', text_en: 'Trainee', text_bn: 'প্রশিক্ষণার্থী' }
        ]
        return userList
      },
      formTitle () {
        return (this.editItemId === 0) ? this.$t('elearning_iabm.training_applications') + ' ' + this.$t('globalTrans.entry') : this.$t('elearning_iabm.training_applications') + ' ' + this.$t('globalTrans.update')
      },
      columns () {
        const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
          { label: this.$t('elearning_iabm.circular_memo_no'), class: 'text-center' },
          { label: this.$t('elearning_iabm.registration_for'), class: 'text-center' },
          { label: this.$t('globalTrans.name'), class: 'text-center' },
          { label: this.$t('elearning_config.organization'), class: 'text-center' },
          { label: this.$t('globalTrans.designation'), class: 'text-center' },
          { label: this.$t('globalTrans.office'), class: 'text-center' },
          { label: this.$t('globalTrans.mobile'), class: 'text-center' },
          { label: this.$t('globalTrans.email'), class: 'text-center' },
        //   { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center w-15' }
        ]

        let keys = []

        if (this.$i18n.locale === 'bn') {
            keys = [
            { key: 'index' },
            { key: 'circular_memo_no' },
            { key: 'user_type_name_bn' },
            { key: 'name_bn' },
            { key: 'org_bn' },
            { key: 'designation_bn' },
            { key: 'office_bn' },
            { key: 'mobile' },
            { key: 'email' },
            // { key: 'status' },
            { key: 'action' }
            ]
        } else {
            keys = [
            { key: 'index' },
            { key: 'circular_memo_no' },
            { key: 'user_type_name' },
            { key: 'name' },
            { key: 'org' },
            { key: 'designation' },
            { key: 'office' },
            { key: 'mobile' },
            { key: 'email' },
            // { key: 'status' },
            { key: 'action' }
            ]
        }

        return labels.map((item, index) => {
            return Object.assign(item, keys[index])
        })
      }
    },
    async created () {
        this.search = Object.assign({}, this.search, {
            org_id: this.$store.state.dataFilters.orgId
        })
        this.loadData()
    },
    mounted () {
      core.index()
    },
    watch: {
      'search.training_type_id': function (newValue) {
        this.trainingCategoryList = this.getCategoryList(newValue)
      },
      'search.training_category_id': function (newValue) {
        this.trainingTitleList = this.getTrainingTitleList(newValue)
      }
    },
    methods: {
      async searchData () {
        this.loadData()
      },
      details (item) {
        this.item = item
      },
      changeStatus (baseUrl, uri, item, destination = null, dropdownName = null) {
        Store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        RestApi.deleteData(baseUrl, `${uri}/${item.id}`).then(response => {
          if (response.success) {
            /** The following line commented as list is reloaded */
            // Store.dispatch('toggleStatus', item)
            if (destination !== null && dropdownName !== null) {
              Store.dispatch('toggleDropdownItemStatus', { itemId: item.id, destination: destination, dropdownName: dropdownName })
            }
            window.vm.$toast.success({
              title: 'Success',
              message: 'Data Updated Successfully',
              color: '#D6E09B'
            })
          } else {
            window.vm.$toast.error({
              title: 'Error',
              message: 'Operation failed! Please, try again.'
            })
          }
          Store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
        })
      },
      approve (item) {
        window.vm.$swal({
          title: window.vm.$t('globalTrans.approveMsg'),
          showCancelButton: true,
          confirmButtonText: window.vm.$t('globalTrans.yes'),
          cancelButtonText: window.vm.$t('globalTrans.no'),
          focusConfirm: false
        }).then((result) => {
          if (result.isConfirmed) {
            this.changeStatus(trainingElearningServiceBaseUrl, trainingApplicationApprove, item, 'training_e_learning', 'trainerEvaluationList')
          }
        })
      },
      reject (item) {
        window.vm.$swal({
          title: window.vm.$t('globalTrans.rejectMsg'),
          showCancelButton: true,
          confirmButtonText: window.vm.$t('globalTrans.yes'),
          cancelButtonText: window.vm.$t('globalTrans.no'),
          focusConfirm: false
        }).then((result) => {
          if (result.isConfirmed) {
            this.changeStatus(trainingElearningServiceBaseUrl, trainingApplicationReject, item, 'training_e_learning', 'trainerEvaluationList')
          }
        })
      },
      loadData () {
        const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        RestApi.getData(trainingElearningServiceBaseUrl, trainingApplicationList, params).then(response => {
          if (response.success) {
              this.$store.dispatch('setList', this.getCustomDataList(response.data.data))
              this.paginationData(response.data)
          }
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        })
      },
      getCustomDataList (data) {
        const listData = data.map(item => {
          const customItem = {}
          const userType = this.userTypeList.find(userTypeObj => userTypeObj.value === item.registration_for)
          customItem.user_type_name = userType?.text_en
          customItem.user_type_name_bn = userType?.text_bn
          const orgObj = this.$store.state.commonObj.organizationProfileList.find(doc => doc.value === parseInt(item.org_id))
          if (typeof orgObj !== 'undefined') {
            customItem.org = orgObj.text_en
            customItem.org_bn = orgObj.text_bn
          } else {
            customItem.org = ''
            customItem.org_bn = ''
          }
          const OfficeTypeListObj = this.$store.state.commonObj.officeTypeList.find(item1 => item1.value === parseInt(item.office_type_id))
          if (typeof OfficeTypeListObj !== 'undefined') {
            customItem.office_type = OfficeTypeListObj.text_en
            customItem.office_type_bn = OfficeTypeListObj.text_bn
          } else {
            customItem.office_type = ''
            customItem.office_type_bn = ''
          }
          const officeObj = this.$store.state.commonObj.officeList.find(doc => doc.value === parseInt(item.office_id))
          if (typeof officeObj !== 'undefined') {
            customItem.office = officeObj.text_en
            customItem.office_bn = officeObj.text_bn
          } else {
            customItem.office = ''
            customItem.office_bn = ''
          }
          const designationObj = this.$store.state.commonObj.designationList.find(doc => doc.value === parseInt(item.designation_id))
          if (typeof designationObj !== 'undefined') {
            customItem.designation = designationObj.text_en
            customItem.designation_bn = designationObj.text_bn
          } else {
            customItem.designation = ''
            customItem.designation_bn = ''
          }
          const fiscalYearObj = this.$store.state.commonObj.fiscalYearList.find(doc => doc.value === parseInt(item.fiscal_year_id))
          if (typeof fiscalYearObj !== 'undefined') {
            customItem.fiscal_year = fiscalYearObj.text_en
            customItem.fiscal_year_bn = fiscalYearObj.text_bn
          } else {
            customItem.fiscal_year = ''
            customItem.fiscal_year_bn = ''
          }
          const trainingTypeObj = this.$store.state.TrainingElearning.commonObj.trainingTypeList.find(doc => doc.value === parseInt(item.training_type_id))
          if (typeof trainingTypeObj !== 'undefined') {
            customItem.training_type = trainingTypeObj.text_en
            customItem.training_type_bn = trainingTypeObj.text_bn
          } else {
            customItem.training_type = ''
            customItem.training_type_bn = ''
          }
          const trainingCategoryObj = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.find(doc => doc.value === parseInt(item.training_category_id))
          if (typeof trainingCategoryObj !== 'undefined') {
            customItem.training_category = trainingCategoryObj.text_en
            customItem.training_category_bn = trainingCategoryObj.text_bn
          } else {
            customItem.training_category = ''
            customItem.training_category_bn = ''
          }
          const trainingTitleObj = this.$store.state.TrainingElearning.commonObj.trainingTitleList.find(doc => doc.value === parseInt(item.training_title_id))
          if (typeof trainingTitleObj !== 'undefined') {
            customItem.training_title = trainingTitleObj.text_en
            customItem.training_title_bn = trainingTitleObj.text_bn
          } else {
            customItem.training_title = ''
            customItem.training_title_bn = ''
          }

          return Object.assign({}, item, customItem)
        })
        return listData
      },
      getCategoryList (typeId) {
        const trainingCategoryList = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.filter(item => item.status === 1)
        if (typeId) {
          return trainingCategoryList.filter(item => item.training_type_id === typeId)
        }
        return trainingCategoryList
      },
      getTrainingTitleList (categoryId) {
        const trainingTitleList = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
        if (categoryId) {
          return trainingTitleList.filter(item => item.training_category_id === categoryId)
        }
        return trainingTitleList
      },
      pdfExportDetails () {
        this.$refs.details.pdfExport()
      },
      pdfExport () {
        const reportTitle = this.$i18n.locale === 'en' ? 'Trainee/Trainer Application' : 'শিক্ষার্থী/প্রশিক্ষক আবেদন'
        ExportPdf.exportPdfDetails(trainingElearningServiceBaseUrl, '/config/report-head/detail', 12, reportTitle, this)
      }
    }
}
</script>
